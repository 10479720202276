import { useRefCallback } from "@enfusion-ui/hooks";
import { styled } from "@enfusion-ui/web-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { noop } from "lodash";
import * as React from "react";
import { SketchPicker } from "react-color";
// This import path is required for jest
import { Button } from "../control";
import { Portal } from "../portal/Portal";
import { InputLabelControl, inputStyles } from "./TextInput";
const Container = styled.div `
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div `
  background-color: var(--background-color-0);
`;
const ValuesContainer = styled.div `
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
`;
const Swatch = styled.div `
  ${inputStyles}
  display: flex;
  flex-direction: row;
`;
const PortalContent = styled.div `
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
`;
const PortalTitle = styled.div `
  height: 30px;
  background-color: var(--background-color-0);
  padding: 0 var(--spacing-l);
  padding-top: var(--spacing-lx);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const PortalSelect = styled.div `
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
const ColorCircle = styled.div `
  height: 22px;
  width: 22px;
  border-radius: 22px;
  background: ${({ value }) => value};
  position: relative;
  z-index: ${({ index }) => index};
  border: 2px solid var(--input-background);
`;
const ColorSquare = styled.div `
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background: ${({ value }) => value};
  position: relative;
  border: ${({ selected }) => (selected ? "1px solid var(--primary)" : "none")};
  box-shadow: ${({ selected }) => selected ? "0 2px 10px 0 rgba(0, 0, 0, 0.3)" : "none"};
  cursor: pointer;

  :hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
  }
`;
const ClearAction = styled.div `
  height: 22px;
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-l);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--${({ disabled }) => (!disabled ? "text-normal" : "text-muted")});
`;
// Note this ColorPicker assumes it's being used inside a react-hook-form
export const ColorPicker = ({ multi, name, label, valueLabel, errors, required, value, defaultValue, onChange, disabled, clearable = true, }) => {
    const [visible, setVisible] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const ref = React.useRef(null);
    const defaultValues = React.useMemo(() => {
        return (!!defaultValue
            ? Array.isArray(defaultValue)
                ? defaultValue
                : [defaultValue]
            : []);
    }, [defaultValue]);
    const [values, setValues] = React.useState(() => {
        if (!!value) {
            return (Array.isArray(value) ? value : [value]);
        }
        return defaultValues;
    });
    const valueLabels = React.useMemo(() => {
        if (!!valueLabel) {
            return (Array.isArray(valueLabel) ? valueLabel : [valueLabel]);
        }
        return values.map((_) => label);
    }, [values, label, valueLabel]);
    const getTypedResValue = useRefCallback((newValue) => {
        return (multi ? newValue : newValue[0]);
    }, [multi]);
    const handleChange = useRefCallback((idx) => ((color) => {
        setValues((state) => {
            const newState = [...state];
            newState[idx] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a || 1})`;
            onChange?.(getTypedResValue(newState));
            return newState;
        });
    }), [multi, value]);
    const openPicker = () => setVisible(true);
    const closePicker = () => {
        setVisible(false);
        setActiveIndex(0);
    };
    const clearInput = useRefCallback((e) => {
        e.stopPropagation();
        setValues(defaultValues);
        onChange?.(getTypedResValue(defaultValues));
    }, [defaultValues, multi, onChange, value]);
    const handleSwitch = React.useCallback((idx) => () => setActiveIndex(idx), []);
    const hasError = !!errors && !!name && !!errors[name];
    return (React.createElement(Container, null,
        React.createElement(InputLabelControl, { name: name, label: label, required: required, errors: errors }),
        React.createElement(Swatch, { supportReadOnly: false, numIcons: 0, disabled: disabled, hasError: hasError, ref: ref, onClick: disabled ? noop : openPicker },
            React.createElement(ValuesContainer, null, values.map((colorValue, idx) => (React.createElement(ColorCircle, { index: values.length - idx, style: { marginLeft: idx > 0 ? -13 : 0 }, key: `${idx}-${colorValue}`, value: colorValue })))),
            clearable && (React.createElement(ClearAction, { disabled: disabled, onClick: disabled ? undefined : clearInput },
                React.createElement(FontAwesomeIcon, { icon: faTimes })))),
        React.createElement(Portal, { open: visible, attachedRef: ref, onClickOutside: closePicker, watchPosition: true, minWidth: null },
            React.createElement(PortalContent, null,
                React.createElement(PortalTitle, null,
                    valueLabels[activeIndex],
                    values.length > 0 && (React.createElement(PortalSelect, null, values.map((colorValue, idx) => (React.createElement(ColorSquare, { selected: idx === activeIndex, style: { marginLeft: idx > 0 ? "var(--spacing)" : 0 }, key: `${idx}-${colorValue}`, value: colorValue, onClick: handleSwitch(idx) })))))),
                React.createElement(SketchPicker, { className: "color-picker-portal", color: values[activeIndex], onChangeComplete: handleChange(activeIndex) }),
                React.createElement(ButtonContainer, null,
                    React.createElement(Button, { theme: "primary", onClick: closePicker, style: { borderTopLeftRadius: 0, borderTopRightRadius: 0 } }, "Done"))))));
};
