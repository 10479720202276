/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducerWithMiddleware, useRefCallback } from "@enfusion-ui/hooks";
import { AppEvent, AppEventCategories } from "@enfusion-ui/types";
import { Model } from "flexlayout-react";
import { last, omit, pick } from "lodash";
import queryString from "query-string";
import * as React from "react";
import { useBeforeUnload } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { AppLogging, errorToast, getParentWindow, isPopOut } from "../../utils";
import { AuthContext } from "../auth";
import { EMPTY_MODEL_JSON, initialTabsContextState, TabsContext, } from "./context";
import { layoutModelMiddleware } from "./layoutModelMiddleware";
import { reducer } from "./reducer";
const STORAGE_KEY_BASE = "e-tab-state";
const getDefaultState = (storageKeyBase, username) => {
    if (!username)
        return initialTabsContextState();
    const val = localStorage.getItem(`${storageKeyBase}-${username}`);
    if (!val)
        return initialTabsContextState();
    try {
        const parsed = JSON.parse(val);
        let tabs = parsed.tabs || [];
        const layoutModel = typeof parsed.layoutModel === "object" && parsed.layoutModel !== null
            ? Model.fromJson(parsed.layoutModel)
            : Model.fromJson(EMPTY_MODEL_JSON);
        const foundTabs = [];
        layoutModel.visitNodes((node) => {
            if (node.getType() === "tab") {
                foundTabs.push(pick(node._attributes, [
                    "config",
                    "id",
                    "name",
                    "icon",
                    "component",
                ]));
            }
        });
        if (foundTabs.length !== tabs.length)
            tabs = foundTabs;
        AppLogging.event({
            event: AppEvent.LayoutTabsLoad,
            category: AppEventCategories.Navigation,
        }, { tabs: tabs.map((i) => omit(i, ["icon"])) });
        return {
            ...parsed,
            tabs,
            layoutModel,
        };
    }
    catch (err) {
        console.error("Failed to parse stored tab state", err);
    }
    return initialTabsContextState();
};
const addIdToTabConfig = (tabConfig) => {
    return {
        ...tabConfig,
        id: tabConfig.unique ? tabConfig.unique : uuidv4(),
    };
};
const NU = "no-user";
export const TabsProviderBase = ({ children, storageKeyBase = STORAGE_KEY_BASE, allowNoUser = false, forceNoUser = false, }) => {
    const { user } = React.useContext(AuthContext) || {};
    const [showUndockConfirmation, setShowUndockConfirmation] = React.useState(false);
    const [hasInitialized, setHasInitialized] = React.useState(false);
    const baseRoute = React.useMemo(() => {
        const data = queryString.parseUrl(window.location.href);
        return last(data.url.split("/")) === "";
    }, [window.location.href]);
    const username = React.useMemo(() => {
        const un = user?.username;
        return forceNoUser ? NU : un ? un : allowNoUser ? NU : undefined;
    }, [user?.username, allowNoUser, forceNoUser]);
    const defaultState = React.useMemo(() => getDefaultState(storageKeyBase, username), [username, storageKeyBase]);
    const [state, dispatch] = useReducerWithMiddleware(reducer, defaultState, [], [layoutModelMiddleware]);
    React.useEffect(() => {
        if (baseRoute && defaultState?.undockedTabs?.length > 0) {
            setShowUndockConfirmation(true);
        }
    }, [baseRoute, defaultState?.undockedTabs?.length]);
    React.useEffect(() => {
        localStorage.setItem(`${storageKeyBase}-${username}`, JSON.stringify({
            ...omit(state, [
                "layoutModel",
                "undockedTabs",
                "onTabWillCloseSubscriptions",
                "onTabCloseSubscriptions",
            ]),
            undockedTabs: (state.undockedTabs ?? []).map((t) => omit(t, ["window"])),
            layoutModel: state.layoutModel ? state.layoutModel.toJson() : null,
        }));
    }, [state, username, storageKeyBase]);
    const openTab = useRefCallback((config, cb) => {
        if (!isPopOut()) {
            AppLogging.event({
                event: AppEvent.LayoutTabOpen,
                category: AppEventCategories.Navigation,
            }, config);
            dispatch({
                type: "add",
                payload: Array.isArray(config)
                    ? config.map(addIdToTabConfig)
                    : [addIdToTabConfig(config)],
                failCallback: cb,
            });
        }
        else {
            getParentWindow()?.postMessage({ action: "open", source: "app-pop-out", config }, "*");
        }
    }, [dispatch]);
    const closeTab = useRefCallback((id, undockedOnly) => {
        AppLogging.event({
            event: AppEvent.LayoutTabClose,
            category: AppEventCategories.Navigation,
        }, { id });
        dispatch({ type: "remove", payload: { id, undockedOnly } });
    }, [dispatch]);
    const focusTab = React.useCallback((id) => dispatch({ type: "focus", payload: { id } }), [dispatch]);
    const renameTab = React.useCallback((id, name) => dispatch({ type: "rename", payload: { id, name } }), [dispatch]);
    const updateTab = React.useCallback((id, config) => dispatch({ type: "update", payload: { id, ...config } }), [dispatch]);
    const undockTab = useRefCallback((id, width, height) => {
        const windowWidth = Math.max(600, width ?? window.screen.width / 2);
        const windowHeight = Math.max(300, height ?? window.screen.height / 2);
        dispatch({
            type: "undock",
            payload: {
                id,
                width: windowWidth,
                height: windowHeight,
            },
        });
    }, []);
    const reUndockTabs = useRefCallback(() => {
        dispatch({
            type: "reUndock",
            payload: {
                ids: (state.undockedTabs ?? []).map((t) => t.id),
            },
        });
        setShowUndockConfirmation(false);
        setHasInitialized(true);
    }, [state.undockedTabs]);
    const dockTab = useRefCallback((id) => {
        if (!isPopOut()) {
            const tabDef = state.undockedTabs?.find((i) => i.id === id);
            if (tabDef) {
                dispatch({
                    type: "dock",
                    payload: {
                        id,
                        tabSetId: tabDef.tabSetId,
                    },
                });
            }
            else {
                errorToast("Unable to re dock");
            }
        }
        else {
            getParentWindow()?.postMessage({ action: "undock", source: "app-pop-out", id }, "*");
        }
    }, [state.undockedTabs]);
    const onTabClose = useRefCallback((callback) => {
        dispatch({ type: "subscribeOnClose", payload: { callback } });
        return () => dispatch({ type: "unsubscribeOnClose", payload: { callback } });
    }, [dispatch]);
    const onTabWillClose = useRefCallback((id, callback) => {
        dispatch({ type: "subscribeOnWillClose", payload: { id, callback } });
        return () => dispatch({ type: "unsubscribeOnWillClose", payload: { id, callback } });
    }, [dispatch]);
    React.useEffect(() => {
        const listener = (event) => {
            if (event.origin === window.location.origin) {
                if (typeof event.data === "object" &&
                    event.data !== null &&
                    event.data.source === "app-pop-out") {
                    if (event.data.action === "close") {
                        closeTab(event.data.id, true);
                    }
                    else if (event.data.action === "open") {
                        openTab(event.data.config);
                    }
                    else if (event.data.action === "undock") {
                        dockTab(event.data.id);
                    }
                }
            }
        };
        window.addEventListener("message", listener, false);
        return () => {
            window.removeEventListener("message", listener);
        };
    }, []);
    const closeAllUndocked = useRefCallback(() => {
        for (const tab of state.undockedTabs ?? []) {
            closeTab(tab.id);
        }
        setShowUndockConfirmation(false);
        setHasInitialized(true);
    }, [state.undockedTabs]);
    const undockedCleanupIntervalRef = React.useRef();
    React.useEffect(() => {
        if (baseRoute) {
            clearInterval(undockedCleanupIntervalRef.current);
            if (state.undockedTabs?.length > 0 && hasInitialized) {
                undockedCleanupIntervalRef.current = setInterval(() => {
                    dispatch({ type: "checkUndocked", payload: {} });
                }, 5000);
            }
        }
    }, [baseRoute, state?.undockedTabs?.length, hasInitialized]);
    const closeAllUndockedWindows = useRefCallback(() => {
        if (baseRoute && !showUndockConfirmation) {
            for (const tabDef of state.undockedTabs ?? []) {
                const tab = tabDef;
                if (tab.window) {
                    tab.window.close();
                }
            }
        }
        return false;
    }, [state.undockedTabs, baseRoute, showUndockConfirmation]);
    useBeforeUnload(closeAllUndockedWindows, "");
    window.getTabState = useRefCallback(() => state, [state]);
    window.closeAllTabs = useRefCallback(() => {
        for (const tab of state.tabs) {
            closeTab(tab.id);
        }
        for (const tab of state.undockedTabs ?? []) {
            closeTab(tab.id);
        }
    }, [state, closeTab]);
    const value = React.useMemo(() => ({
        ...state,
        openTab,
        closeTab,
        focusTab,
        renameTab,
        updateTab,
        undockTab,
        dockTab,
        onTabClose,
        onTabWillClose,
        closeAllUndocked,
        reUndockTabs,
        showUndockConfirmation,
    }), [
        state,
        openTab,
        closeTab,
        focusTab,
        renameTab,
        updateTab,
        undockTab,
        dockTab,
        onTabClose,
        onTabWillClose,
        closeAllUndocked,
        reUndockTabs,
        showUndockConfirmation,
    ]);
    return React.createElement(TabsContext.Provider, { value: value }, children);
};
export function useTabs() {
    const context = React.useContext(TabsContext);
    if (context === undefined)
        throw new Error("useTabs must be used within an TabsContext");
    return context;
}
