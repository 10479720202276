import { cloneDeep, isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { WIDGET_DEFINITIONS } from "../widgets/definitions";
import { BREAK_POINT_KEYS_V2 } from "./constants";
const basePlacementV2Ignored = BREAK_POINT_KEYS_V2.reduce((res, key) => ({ ...res, [key]: [] }), {});
export const defaultDashboardConfigV2 = {
    main: { widgets: [], placement: {} },
    sticky: { widgets: [], placement: {} },
};
export const dashboardsProviderDefaultState = {
    name: "",
    instanceId: null,
    version: 0,
    configVersion: 2,
    hasUnsavedChanges: false,
    oldConfig: {
        ...cloneDeep(defaultDashboardConfigV2),
    },
    oldDatasources: [],
    oldSettings: {
        compactType: null,
        theme: "dashDark",
        isLayoutLocked: false,
        orientation: "portrait",
    },
    config: {
        ...cloneDeep(defaultDashboardConfigV2),
    },
    placement: {},
    datasources: [],
    settings: {
        compactType: null,
        theme: "dashDark",
        isLayoutLocked: false,
        orientation: "portrait",
    },
    error: false,
    loading: true,
    channelData: {},
    channelKeys: [],
    fileMissing: false,
};
export function getChannelId(id) {
    return typeof id !== "undefined" ? id : -1;
}
export function getChannelDataId(id) {
    if (!id)
        return null;
    return `${id.widgetId}-${id.key}`;
}
export function dashboardsProviderReducer(state, action) {
    const getUpdatedLayout = (gridId, payloadRecord) => {
        return Object.keys(payloadRecord).reduce((acc, sizeId) => {
            const payload = state.config[gridId]?.placement?.[sizeId] || [];
            const res = payloadRecord[sizeId]?.reduce((res, def) => {
                const idx = payload.findIndex((i) => i.i === def.i);
                const layoutConfig = payload[idx];
                if (!layoutConfig ||
                    (layoutConfig &&
                        (layoutConfig.h !== def.h ||
                            layoutConfig.w !== def.w ||
                            layoutConfig.x !== def.x ||
                            layoutConfig.y !== def.y))) {
                    res.changed = true;
                }
                res.newState.push(def);
                return res;
            }, {
                newState: [],
                changed: false,
            });
            acc.newState[sizeId] = res?.newState;
            acc.changed = acc.changed || res?.changed;
            return acc;
        }, {
            newState: {},
            changed: false,
        });
    };
    if (action.type === "hydrate") {
        const { datasources, config, version, settings = dashboardsProviderDefaultState.settings, name, instanceId, hasUnsavedChanges, } = action.payload;
        return {
            ...state,
            version,
            name,
            hasUnsavedChanges: hasUnsavedChanges ?? false,
            config: cloneDeep(config),
            datasources: cloneDeep(datasources),
            settings: cloneDeep(settings),
            oldConfig: cloneDeep(config),
            oldDatasources: cloneDeep(datasources),
            oldSettings: cloneDeep(settings),
            error: false,
            fileMissing: false,
            loading: false,
            instanceId,
        };
    }
    else if (action.type === "set-has-error") {
        return {
            ...state,
            error: true,
            loading: false,
        };
    }
    else if (action.type === "set-file-missing") {
        return { ...state, fileMissing: true, loading: false };
    }
    else if (action.type === "loading") {
        return { ...state, loading: true };
    }
    else if (action.type === "revert") {
        return {
            ...state,
            datasources: cloneDeep(state.oldDatasources),
            config: cloneDeep(state.oldConfig),
            settings: cloneDeep(state.oldSettings),
            hasUnsavedChanges: false,
        };
    }
    else if (action.type === "save") {
        return {
            ...state,
            name: action.payload.name,
            version: state.version + 1,
            oldDatasources: cloneDeep(state.datasources),
            oldConfig: cloneDeep(state.config),
            oldSettings: cloneDeep(state.settings),
            hasUnsavedChanges: false,
        };
    }
    else if (action.type === "revert-default") {
        const datasources = cloneDeep(action.payload.datasources);
        const config = cloneDeep(action.payload.config);
        const settings = cloneDeep(action.payload.settings ??
            dashboardsProviderDefaultState.settings);
        return {
            ...state,
            hasUnsavedChanges: !isEqual(state.oldConfig, config) ||
                !isEqual(state.oldSettings, settings) ||
                !isEqual(state.oldDatasources, datasources),
            config,
            datasources,
            settings,
            error: false,
            fileMissing: false,
            loading: false,
        };
    }
    else if (action.type === "add-datasource") {
        return {
            ...state,
            hasUnsavedChanges: true,
            datasources: [...(state.datasources ?? []), action.payload],
        };
    }
    else if (action.type === "update-datasource") {
        const newDatasources = [...state.datasources];
        const idx = newDatasources.findIndex((i) => i.id === action.payload.id);
        const hasChanges = isEqual(newDatasources[idx], action.payload);
        if (!hasChanges)
            return state;
        newDatasources[idx] = action.payload;
        return {
            ...state,
            hasUnsavedChanges: true,
            datasources: newDatasources,
        };
    }
    else if (action.type === "update-layout") {
        const { gridId, layout } = action.payload;
        const changes = getUpdatedLayout(gridId, layout);
        if (changes.changed) {
            return {
                ...state,
                hasUnsavedChanges: true,
                config: {
                    ...state.config,
                    [gridId]: {
                        ...(state.config[gridId] || { widgets: [] }),
                        placement: {
                            ...((state.config[gridId] || {})?.placement ?? {}),
                            ...changes.newState,
                        },
                    },
                },
            };
        }
    }
    else if (action.type === "update-widget") {
        const { gridId, id, def } = action.payload;
        const idx = state.config[gridId].widgets.findIndex((i) => i.id === id);
        if (idx > -1) {
            const newWidgets = [...state.config[gridId].widgets];
            newWidgets[idx] = {
                ...newWidgets[idx],
                ...def,
            };
            const changed = JSON.stringify(state.config[gridId].widgets) !==
                JSON.stringify(newWidgets);
            return {
                ...state,
                hasUnsavedChanges: changed,
                config: {
                    ...state.config,
                    [gridId]: {
                        ...state.config[gridId],
                        widgets: newWidgets,
                    },
                },
            };
        }
    }
    else if (action.type === "add-widget") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { breakpointKey, type, widgetLayout, gridId } = action.payload;
        const id = uuidv4();
        const hiddenWidget = WIDGET_DEFINITIONS[type].hidden === true;
        return {
            ...state,
            hasUnsavedChanges: true,
            config: {
                ...state.config,
                [gridId]: {
                    ...state.config[gridId],
                    widgets: [
                        ...state.config[gridId].widgets,
                        {
                            id,
                            type,
                            config: WIDGET_DEFINITIONS[type].defaultConfig,
                        },
                    ],
                    placement: {
                        ...state.config[gridId].placement,
                        [breakpointKey]: [
                            ...(state.config[gridId].placement[breakpointKey] || []),
                            {
                                x: hiddenWidget ? 0 : widgetLayout.x,
                                y: hiddenWidget ? 0 : widgetLayout.y,
                                w: hiddenWidget ? 0 : widgetLayout.w,
                                h: hiddenWidget ? 0 : widgetLayout.h,
                                i: id,
                            },
                        ],
                    },
                },
            },
        };
    }
    else if (action.type === "remove-widget") {
        const { gridId, id } = action.payload;
        return {
            ...state,
            hasUnsavedChanges: true,
            config: {
                ...state.config,
                [gridId]: {
                    ...state.config[gridId],
                    widgets: [...state.config[gridId].widgets.filter((w) => w.id !== id)],
                },
            },
        };
    }
    else if (action.type === "clear-all-widgets") {
        return {
            ...state,
            hasUnsavedChanges: true,
            config: {
                main: { widgets: [], placement: {} },
                sticky: { widgets: [], placement: {} },
            },
        };
    }
    else if (action.type === "update-channel-data") {
        return {
            ...state,
            channelData: { ...state.channelData, ...action.payload.data },
        };
    }
    else if (action.type === "remove-channel-keys") {
        const widgetId = action.payload.widgetId;
        const keysToRemove = state.channelKeys.filter((i) => i.widgetId === widgetId);
        if (keysToRemove.length === 0) {
            return state;
        }
        const newChannelData = { ...state.channelData };
        for (const entry of keysToRemove) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            delete newChannelData[getChannelDataId(entry)];
        }
        return {
            ...state,
            channelKeys: state.channelKeys.filter((i) => i.widgetId !== widgetId),
            channelData: newChannelData,
        };
    }
    else if (action.type === "add-channel-keys") {
        const channelKeys = state.channelKeys;
        const widgetId = action.payload.widgetId;
        const keysToAdd = action.payload.keys;
        if (keysToAdd.length === 0) {
            return state;
        }
        const newChannelKeys = [
            ...channelKeys.filter((i) => i.widgetId !== widgetId),
            ...keysToAdd.map((entry) => ({
                ...entry,
                widgetId,
                description: entry.description || "",
            })),
        ];
        return {
            ...state,
            channelKeys: newChannelKeys,
        };
    }
    else if (action.type === "change-setting") {
        const { key, value } = action.payload;
        return {
            ...state,
            settings: { ...state.settings, [key]: value },
            hasUnsavedChanges: true,
        };
    }
    return state;
}
