import { Model } from "flexlayout-react";
import * as React from "react";
import { TabsContext } from "../tabs";
const openTab = () => { };
const closeTab = () => { };
const focusTab = () => { };
const renameTab = () => { };
const undockTab = () => { };
const dockTab = () => { };
const onTabClose = () => { };
const onTabWillClose = () => { };
const reUndockTabs = () => { };
const closeAllUndocked = () => { };
const updateTab = () => { };
const createLayoutModel = (tabs) => {
    return {
        global: {
            splitterSize: 2,
            tabSetTabStripHeight: 38,
            tabEnableRename: false,
        },
        borders: [],
        layout: {
            type: "row",
            weight: 100,
            children: [
                {
                    type: "tabset",
                    weight: 50,
                    selected: 0,
                    active: true,
                    children: tabs.map((item) => ({
                        type: "tab",
                        enableClose: false,
                        name: item.name,
                        component: item.component,
                    })),
                },
            ],
        },
    };
};
export const MockTabsProvider = ({ tabs = [
    {
        id: "test1",
        name: "test1",
        component: "report",
    },
    {
        id: "test2",
        name: "test2",
        component: "oems",
    },
], undockedTabs = [], children, }) => (React.createElement(TabsContext.Provider, { value: {
        tabs,
        undockedTabs,
        layoutModel: Model.fromJson(createLayoutModel(tabs)),
        onTabCloseSubscriptions: [],
        onTabWillCloseSubscriptions: { test: [] },
        openTab,
        closeTab,
        focusTab,
        renameTab,
        undockTab,
        dockTab,
        onTabClose,
        onTabWillClose,
        reUndockTabs,
        closeAllUndocked,
        showUndockConfirmation: false,
        updateTab,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } }, children));
