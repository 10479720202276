import { ConfirmationModal } from "@enfusion-ui/web-components";
import { TabsProviderBase, useTabs, } from "@enfusion-ui/web-core";
import * as React from "react";
function ConfirmReopenModal() {
    const { undockedTabs, showUndockConfirmation, reUndockTabs, closeAllUndocked, } = useTabs();
    const title = React.useMemo(() => `Reopen Popped Out Windows? ${undockedTabs && undockedTabs?.length >= 2
        ? `(Note: Allow multiple windows to restore remaining ${undockedTabs.length - 1} undocked tabs)`
        : ""}`, [undockedTabs]);
    return (React.createElement(ConfirmationModal, { open: showUndockConfirmation, title: title, onCancel: closeAllUndocked, onSubmit: reUndockTabs, submitActionTheme: "primary" }));
}
export const TabsProvider = ({ children, ...rest }) => {
    return (React.createElement(TabsProviderBase, { ...rest },
        children,
        React.createElement(ConfirmReopenModal, null)));
};
