import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { components, } from "react-select";
import { Checkbox } from "./Checkbox";
import { Select } from "./Select";
export const ValuesWrapper = styled.div `
  position: absolute;
  top: 25%;
  left: 10px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;
const getDisplayValues = (values, showValuesOnSelection = false) => {
    return values
        .map(({ value, label }) => (showValuesOnSelection ? value : label))
        .join(", ");
};
const ValueContainer = ({ children, ...props }) => {
    const placeholder = children[0];
    const searchInput = children[1];
    const showValuesOnSelection = props?.selectProps?.showValuesOnSelection ?? false;
    const displayValues = getDisplayValues(props.getValue(), showValuesOnSelection);
    const showDisplayValues = props.selectProps.inputValue ? false : true;
    return (React.createElement(components.ValueContainer, { ...props },
        displayValues.length === 0 ? (placeholder) : showDisplayValues ? (React.createElement(ValuesWrapper, { title: displayValues }, displayValues)) : null,
        searchInput));
};
const Option = (props) => {
    return (React.createElement(components.Option, { ...props },
        React.createElement("div", { style: { display: "flex", columnGap: "var(--spacing-l)" } },
            React.createElement(Checkbox, { noContainer: true, checked: props.isSelected, iconSize: "1x", iconStyle: {} }),
            React.createElement("label", null, props.label))));
};
export const MultiSelect = ({ onChange, components, ...props }) => {
    const handleChange = (values) => {
        onChange(values);
    };
    return (React.createElement(Select, { onChange: (values) => {
            handleChange(values);
        }, components: { Option, ValueContainer, ...components }, isMulti: true, closeMenuOnSelect: false, hideSelectedOptions: false, minWidth: 150, ...props }));
};
