import { useRefCallback } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { styled } from "@enfusion-ui/web-core";
import * as React from "react";
import { createBaseTabList } from "./BaseTabs";
const getTextColor = ({ active, disabled, }) => {
    if (disabled && !active)
        return "var(--text-muted)";
    return "var(--text-normal)";
};
const getBgColor = ({ active, disabled, }) => {
    if (active && disabled)
        return "var(--background-color-2)";
    return active ? "var(--primary)" : "var(--background-color-1)";
};
const getHoverBgColor = ({ active, disabled, }) => {
    if (active && disabled)
        return "var(--background-color-2)";
    if (disabled)
        return "var(--background-color-1)";
    return active ? "var(--primary-hover)" : "var(--background-color-1-hover)";
};
const TabContainer = styled.div `
  font-size: small;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  min-width: min-content;
  height: 23px;
  position: relative;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "default")};
  color: ${(props) => getTextColor(props)};
  border-radius: var(--radius);
  background-color: ${(props) => getBgColor(props)};
  margin-right: var(--spacing-l);

  :hover {
    ${({ theme }) => ["light"].includes(theme.key) ? "color: var(--text-inverted);" : ""}
    background-color: ${(props) => getHoverBgColor(props)};
  }
`;
const TabTitleContainer = styled.div `
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  white-space: nowrap;
  min-width: 0px;
  overflow: hidden;
`;
const Count = styled.div `
  height: 14px;
  padding: 1px 4px 1px 4px;
  margin-left: 6px;
  color: var(--text-normal);
  background-color: var(--background-accent);
  border-radius: var(--radius-l);

  justify-content: center;
  align-items: center;
  display: flex;
  line-height: 10px;
`;
function createTab() {
    return React.forwardRef(function Tab({ tab, getTabLabel, onClick, active, disabled = false }, ref) {
        const handleClick = useRefCallback(() => {
            if (!disabled)
                onClick();
        }, [disabled, onClick]);
        const label = React.useMemo(() => {
            return getTabLabel?.(tab.key) ?? tab.label ?? "";
        }, [tab, getTabLabel]);
        return (React.createElement(TabContainer, { onClick: handleClick, active: active, disabled: disabled, title: label, ref: ref, "data-testid": createTestId(`tab-${label}`), className: "tabs-container" },
            React.createElement(TabTitleContainer, null,
                React.createElement("div", null, label),
                tab.count && React.createElement(Count, { active: active }, tab.count))));
    });
}
export function createTertiaryTabList() {
    const TabList = createBaseTabList();
    const Tab = createTab();
    return function TertiaryTabList(props) {
        return React.createElement(TabList, { ...props, height: 23, TabComponent: Tab });
    };
}
export const TertiaryTabList = createTertiaryTabList();
