export const WebReportQueryTypes = {
    Book: "Book",
    CustodianAccount: "CustodianAccount",
    Position: "Position",
    PositionCashFlow: "PositionCashFlow",
    Instrument: "Instrument",
    OrderBlotter: "OrderBlotter",
    TradeValuation: "TradeValuation",
    TradeBlotter: "TradeBlotter",
    GLBalanceSheet: "GLBalanceSheet",
    GLCashBalances: "GLCashBalances",
    GLCashFlowStatement: "GLCashFlowStatement",
    GLIncomeStatement: "GLIncomeStatement",
    GLPosition: "GLPosition",
    GLDistributionLine: "GLDistributionLine",
    GLTrialBalances: "GLTrialBalances",
    GLCashActivity: "GLCashActivity",
    Quotes: "Quotes",
    InvestmentReport: "InvestmentReport",
    PositionEvent: "PositionEvent",
    ComplianceBlotter: "ComplianceBlotter",
    CounterpartyCommission: "CounterpartyCommission",
    CashLadder: "CashLadder",
    CorporateAction: "CorporateAction",
};
