import BigNumber from "bignumber.js";
import { format, isEqual, parseISO } from "date-fns";
import { isUndefined } from "lodash";
import numbro from "numbro";
import * as numbroLanguages from "numbro/dist/languages.min.js";
import { UNIX_START_OF_TIME } from "./constants";
import { currencySymbols } from "./currencySymbols";
import { parseStringToNumber } from "./parsers";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const langs = numbroLanguages;
const separator = ".";
let delimiters = {
    thousands: ",",
    decimal: ".",
};
export function setNumbroLanguage(lang) {
    if (langs[lang]) {
        delimiters = langs[lang].delimiters || {
            thousands: ",",
            decimal: ".",
        };
    }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defaultOption(val, defaultVal) {
    if (typeof val !== "undefined")
        return val;
    return defaultVal;
}
const zeroString = (size) => [...new Array(size)].fill("0").join("");
export const getDecimalSeparator = () => delimiters.decimal;
export function cleanNumber(value) {
    const isNegative = value.trim().startsWith("-");
    const cleanPattern = new RegExp(`[^0-9${separator}e]`, "g");
    const cleaned = value
        .replace(/e-/g, "ee")
        .replace(cleanPattern, "")
        .replace(/ee/g, "e-");
    return `${isNegative ? "-" : ""}${cleaned}`.trim();
}
export function parseNumber(value, asNumber) {
    const val = new BigNumber(cleanNumber(value?.toString() ?? ""));
    if (asNumber)
        val.valueOf();
    return val;
}
export function countDecimalPlaces(num) {
    const bg = num instanceof BigNumber ? num : parseNumber(num);
    const count = bg.absoluteValue().dp();
    if (count === 0 && typeof num === "string")
        return (cleanNumber(num).split(separator)[1] ?? "").length ?? 0;
    return count;
}
function valCheck(val, isNaN = (i) => i === "NaN", getStr = (i) => `${i}`) {
    return !(val === null ||
        isUndefined(val) ||
        isNaN(val) ||
        getStr(val).trim().length === 0);
}
export function formatNumber(value, params = {}) {
    const options = params === null ? {} : params;
    if (!valCheck(value))
        return "";
    const cleaned = typeof value === "string"
        ? parseStringToNumber(value)
        : new BigNumber(value);
    if (!valCheck(cleaned, (i) => i.isNaN(), (i) => i.valueOf()))
        return "";
    let fractionDigits = null;
    let reduce = false;
    let formatNegative = true;
    let useGrouping = true;
    let mobileCCY = false;
    let currency;
    let style = "number";
    let forceAverage;
    const isNegative = cleaned.isNegative();
    if (typeof options === "number") {
        fractionDigits = [options, options, options];
    }
    else if (Array.isArray(options)) {
        fractionDigits = [...options, options[1]]; // set break units to max size
    }
    else {
        if (typeof options.fractionDigits === "number") {
            fractionDigits = [
                options.fractionDigits,
                options.fractionDigits,
                options.fractionDigits,
            ];
        }
        else if (Array.isArray(options.fractionDigits)) {
            fractionDigits = [
                ...options.fractionDigits,
                options.fractionDigits[1],
            ]; // set break units to max size
        }
        reduce = defaultOption(options.reduce, reduce);
        forceAverage = defaultOption(options.forceAverage, forceAverage);
        formatNegative = defaultOption(options.formatNegative, formatNegative);
        currency = options.currency;
        style = defaultOption(options.style, style);
        useGrouping = defaultOption(options.useGrouping, useGrouping);
        mobileCCY = defaultOption(options.mobileCCY, mobileCCY);
    }
    let currencySymbol = currency || undefined;
    const key = mobileCCY ? "mobile" : "base";
    if (currencySymbol &&
        currencySymbols[key][currencySymbol]) {
        currencySymbol = currencySymbols[key][currencySymbol];
    }
    const minDigits = fractionDigits
        ? Math.max(Math.min(fractionDigits[0], fractionDigits[1]), 0)
        : null;
    const maxDigits = fractionDigits
        ? Math.max(fractionDigits[0], fractionDigits[1], 0)
        : null;
    const breakDigits = fractionDigits ? fractionDigits[2] : null;
    const formatOptions = JSON.parse(JSON.stringify({
        output: style === "percent" ? "percent" : undefined,
        thousandSeparated: useGrouping,
        mantissa: maxDigits ?? undefined,
        forceAverage,
        average: reduce,
        abbreviations: {
            thousand: "k",
            million: "mm",
            billion: "b",
            trillion: "t",
        },
    }));
    formatNegative = formatNegative && isNegative;
    const parsed = numbro(cleaned.valueOf());
    let res = "";
    try {
        res = parsed.format(formatOptions);
    }
    catch (err) {
        console.log("failed to format", err);
        console.log(cleaned.valueOf(), value);
        return "failed to format";
    }
    if (minDigits !== null && maxDigits !== null && breakDigits !== null) {
        if (minDigits < maxDigits) {
            let mantissa = maxDigits;
            const endZeros = zeroString(breakDigits);
            while (mantissa > minDigits && cleanNumber(res).endsWith(endZeros)) {
                mantissa = Math.max(mantissa - breakDigits, minDigits);
                res = numbro(cleaned.valueOf()).format({ ...formatOptions, mantissa });
            }
        }
    }
    res = res.trim();
    if (res.startsWith("-") || res.startsWith("+"))
        res = res.slice(1);
    if (style === "currency")
        res = `${currencySymbol || ""}${res}`;
    if (isNegative)
        res = formatNegative ? `(${res})` : `-${res}`;
    return res;
}
export function formatQuoteCellValue(quote, formatNegative = true) {
    // Eventually this will use quote.quotationFormat
    // Hard-coding 2 decimal places to match mazzika for now
    return formatNumber(quote, { fractionDigits: 2, formatNegative });
}
export function formatPercentage(value, fractionDigits = 2, formatNegative = false, forceAverage = undefined, reduce = false, addPlusSign = false, options = {}) {
    const res = formatNumber(value, {
        ...options,
        fractionDigits,
        formatNegative,
        forceAverage,
        reduce,
        style: "percent",
    });
    if (res.includes("Infinity")) {
        console.warn("got infinity", value, res);
        return res.replace(`${separator}00`, "");
    }
    if (addPlusSign && typeof value === "number")
        return (value > 0 ? "+" : "") + res;
    return res;
}
export function formatCurrency(value, currency, options = {}) {
    const { fractionDigits = 2, formatNegative = true, forceAverage, reduce = false, mobileCCY = false, } = options;
    return formatNumber(value, {
        fractionDigits,
        formatNegative,
        forceAverage,
        reduce,
        style: "currency",
        currency,
        mobileCCY,
    });
}
export function formatDateTimeFormat(value, dateTimeFormat) {
    if (!value)
        return "";
    if (typeof value !== "string") {
        console.warn("date not a string", value);
        return typeof value !== "undefined" && value !== null ? `${value}` : null;
    }
    let parse = (val) => parseISO(val);
    if (!dateTimeFormat) {
        const segments = value.split(" ");
        if (segments.length === 2) {
            segments[0] = segments[0].replace(/\./g, "-");
            value = segments.join("T");
        }
        else {
            parse = (val) => new Date(val);
        }
        dateTimeFormat = "yyyy-MM-dd kk:mm:ss.SSS";
    }
    try {
        return format(parse(value), dateTimeFormat);
    }
    catch (e) {
        console.warn("error parsing date", e);
        return value;
    }
}
// These three formatters are used for OEMS
export function formatDate(value) {
    if (!value)
        return "";
    return formatDateTimeFormat(value, "P");
}
export function formatTime(value) {
    if (!value)
        return "";
    return formatDateTimeFormat(value, "pp");
}
export function formatDateTime(value) {
    if (!value)
        return "";
    return formatDateTimeFormat(value, "Ppp");
}
export function formatPositionIdentifierScenarioCellValue(value) {
    const { asOfDate, accountId, bookId, instrumentId, trsId, positionBlock, ignoreBook, dealId, financialSubtype, } = value;
    const parsedAsOfDate = parseISO(asOfDate);
    const isLatest = isEqual(parsedAsOfDate, UNIX_START_OF_TIME);
    const identifierTokens = [];
    identifierTokens.push(accountId, bookId, instrumentId, trsId);
    if (positionBlock)
        identifierTokens.push(positionBlock);
    identifierTokens.push(ignoreBook);
    if (dealId)
        identifierTokens.push(dealId);
    if (financialSubtype)
        identifierTokens.push(financialSubtype);
    const formattedValue = `${isLatest ? "Latest" : format(parsedAsOfDate, "yyyy-MM-dd")}-R-${identifierTokens.join("/")}`;
    return formattedValue;
}
export function formatForUrl(query) {
    return Object.entries(query).reduce((acc, [key, value]) => {
        if (value === null || value === undefined) {
            return acc;
        }
        if (value instanceof Date) {
            return { ...acc, [key]: value.toISOString() };
        }
        if (typeof value === "string" ||
            typeof value === "number" ||
            typeof value === "boolean") {
            return { ...acc, [key]: value };
        }
        return {
            ...acc,
            [key]: value.dateSelectionType === "AsOfDate"
                ? value.asOfDate
                : value.dateSelectionType,
        };
    }, {});
}
export function getAverageUnits(value, fractionDigits) {
    const options = typeof value === "number" ? [value] : value;
    const entry = Math.max(...options.map((v) => Math.abs(v).toFixed(0).length / 3));
    const params = {
        fractionDigits,
        reduce: true,
        forceAverage: undefined,
        formatNegative: false,
    };
    if (entry <= 1)
        params.reduce = false;
    else if (entry <= 2)
        params.forceAverage = "thousand";
    else if (entry <= 3)
        params.forceAverage = "million";
    else if (entry <= 4)
        params.forceAverage = "billion";
    else
        params.forceAverage = "trillion";
    return params;
}
