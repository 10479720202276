import queryString from "query-string";
export function getUrlOrderType(type) {
    switch (type) {
        case "equity":
        case "futureSpread":
            return "";
        default:
            return `/${type}`;
    }
}
function getUrlFillOrderType(type) {
    switch (type) {
        case "equity":
            return "listed";
        case "futureSpread":
            return "futurespread";
        case "cdx":
            return "cds";
        default:
            return `${type}`;
    }
}
export const fillVoiceOrderFactory = (callCreator) => {
    return function fillVoiceOrder(type, body, acceptWarnings, abortController) {
        const factoryFetch = callCreator((type, body, acceptWarnings) => [
            queryString.stringifyUrl({
                url: `/internal/api/oms/v1/orders/fill/${getUrlFillOrderType(type)}`,
                query: { acceptWarnings },
            }),
            { body },
        ]);
        return factoryFetch.FETCH(type, body, acceptWarnings, abortController);
    };
};
export const saveOrderFactory = (callCreator) => {
    return function saveOrder(type, body, query = {}, abortController) {
        const factoryFetch = callCreator((type, body, query) => [
            queryString.stringifyUrl({
                url: `/internal/api/oms/v1/orders${getUrlOrderType(type)}/save`,
                query,
            }),
            { body },
        ]);
        return factoryFetch.FETCH(type, body, query, abortController);
    };
};
export const saveAndTransmitOrderFactory = (callCreator) => {
    return function saveAndTransmitOrder(type, body, query = {}, abortController) {
        const factoryFetch = callCreator((type, body, query) => [
            queryString.stringifyUrl({
                url: `/internal/api/oms/v1/orders${getUrlOrderType(type)}/save/transmit`,
                query,
            }),
            { body },
        ]);
        return factoryFetch.FETCH(type, body, query, abortController);
    };
};
export const getSplitOrderFactory = (callCreator) => {
    return function getSplitOrder(type, parentOrderId, abortController) {
        const factoryFetch = callCreator((type, parentOrderId) => queryString.stringifyUrl({
            url: `/internal/api/oms/v1/orders/split${getUrlOrderType(type)}`,
            query: { parentOrderId },
        }));
        return factoryFetch.FETCH(type, parentOrderId, abortController);
    };
};
export const getOrderFactory = (callCreator) => {
    return function getOrder(type, orderId, abortController) {
        const factoryFetch = callCreator((type, orderId) => queryString.stringifyUrl({
            url: `/internal/api/oms/v1/orders${getUrlOrderType(type)}/${orderId}`,
        }));
        return factoryFetch.FETCH(type, orderId, abortController);
    };
};
