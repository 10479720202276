import { formatDateToStandardFormat } from "@enfusion-ui/utils";
import { REST_API } from "../../api";
import { UNKNOWN_INSTRUMENT_ID, UNKNOWN_INSTRUMENT_INFO, } from "../../constants";
import { initialVarSwapOrderFormState, } from "../orders";
import { convertFormValueToVarSwapOrder, convertFormValueToVarSwapSplitOrder, convertVarSwapOrderToFormValues, } from "../utils";
const overrideWithDefaults = (orderInfo) => {
    const defaultValues = {
        ...initialVarSwapOrderFormState,
        ...orderInfo,
        atm: orderInfo.atm ?? initialVarSwapOrderFormState.atm,
        dayCount: orderInfo.dayCount ?? initialVarSwapOrderFormState.dayCount,
        effectiveDate: orderInfo.effectiveDate ?? initialVarSwapOrderFormState.effectiveDate,
        expiryDate: orderInfo.expiryDate ?? initialVarSwapOrderFormState.expiryDate,
        fixingRate: orderInfo.fixingRate ?? null,
        holidayCenters: orderInfo.holidayCenters ?? [],
        indicativeStrike: orderInfo.indicativeStrike ??
            initialVarSwapOrderFormState.indicativeStrike,
        lowerBarrier: orderInfo.lowerBarrier ?? initialVarSwapOrderFormState.lowerBarrier,
        lowerCap: orderInfo.lowerCap ?? initialVarSwapOrderFormState.lowerCap,
        notionalCcy: orderInfo.notionalCcy ?? null,
        nratio: orderInfo.nratio ?? initialVarSwapOrderFormState.nratio,
        referenceInstrumentId: orderInfo.referenceInstrumentId ??
            initialVarSwapOrderFormState.referenceInstrumentId,
        referenceInstrumentPrice: orderInfo.referenceInstrumentPrice ??
            initialVarSwapOrderFormState.referenceInstrumentPrice,
        strike: orderInfo.strike ?? initialVarSwapOrderFormState.strike,
        underlyingInstrumentId: orderInfo.underlyingInstrumentId ?? UNKNOWN_INSTRUMENT_ID,
        upperBarrier: orderInfo.upperBarrier ?? initialVarSwapOrderFormState.upperBarrier,
        upperCap: orderInfo.upperCap ?? initialVarSwapOrderFormState.upperCap,
        varianceUnits: orderInfo.varianceUnits ?? initialVarSwapOrderFormState.varianceUnits,
        vegaNotional: orderInfo.vegaNotional ?? initialVarSwapOrderFormState.vegaNotional,
        complianceState: orderInfo.complianceState ?? initialVarSwapOrderFormState.complianceState,
        limitPrice: orderInfo.limitPrice ?? initialVarSwapOrderFormState.limitPrice,
        orderType: orderInfo.orderType ?? initialVarSwapOrderFormState.orderType,
        webOrderType: orderInfo.webOrderType,
        currentCalculatorType: orderInfo.calculatorType,
        accountAllocation: orderInfo.accountAllocation,
        counterpartyMemo: orderInfo.counterpartyMemo ??
            initialVarSwapOrderFormState.counterpartyMemo,
        instructions: orderInfo.instructions || "",
        locateId: orderInfo.locateId ?? initialVarSwapOrderFormState.locateId,
    };
    return defaultValues;
};
export const handleOrderChange = async (updatedOrder, prevFormValues) => convertVarSwapOrderToFormValues(updatedOrder, prevFormValues);
export const getDefaultOrderState = async (restServer, id) => {
    try {
        const response = await REST_API(restServer).OEMS.GET_DEFAULT_ORDER.FETCH(id || undefined);
        const newDefaultValues = overrideWithDefaults(response);
        return newDefaultValues;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
export const getOrderState = async (restServer, id) => {
    const orderInfo = await REST_API(restServer).OEMS.GET_ORDER("varSwap", id);
    const underlyingInstrument = orderInfo.underlyingInstrumentId
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(orderInfo.underlyingInstrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    const referenceInstrument = orderInfo.referenceInstrumentId
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(orderInfo.referenceInstrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    const fixingRateInstrument = orderInfo.fixingRate
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(orderInfo.fixingRate)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertVarSwapOrderToFormValues(orderInfo),
        underlyingInstrument,
        referenceInstrument,
        fixingRateInstrument,
    };
};
export const getSplitOrderState = async (restServer, id) => {
    const { childOrder } = await REST_API(restServer).OEMS.GET_SPLIT_ORDER("varSwap", id);
    const underlyingInstrument = childOrder.underlyingInstrumentId
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(childOrder.underlyingInstrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    const referenceInstrument = childOrder.referenceInstrumentId
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(childOrder.referenceInstrumentId)
        : UNKNOWN_INSTRUMENT_INFO;
    const fixingRateInstrument = childOrder.fixingRate
        ? await REST_API(restServer).INSTRUMENT.GET_INSTRUMENT_INFO.FETCH(childOrder.fixingRate)
        : UNKNOWN_INSTRUMENT_INFO;
    return {
        ...convertVarSwapOrderToFormValues(childOrder),
        underlyingInstrument,
        referenceInstrument,
        fixingRateInstrument,
    };
};
export const convertFormValuesToOrder = (
// eslint-disable-next-line @typescript-eslint/no-explicit-any
previousFormState, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
newFormState) => {
    if (!newFormState) {
        return convertFormValueToVarSwapOrder(previousFormState);
    }
    return convertFormValueToVarSwapOrder({
        ...previousFormState,
        ...newFormState,
    });
};
export const submitOrder = async (restServer, submissionInfo, { transmit, orderId, splitOrder, acceptComplianceWarnings, }) => {
    try {
        const bodyContent = {
            ...submissionInfo,
            id: !splitOrder || (splitOrder && acceptComplianceWarnings) ? orderId : 0,
        };
        const newContent = {
            ...bodyContent,
            effectiveDate: !bodyContent.effectiveDate
                ? undefined
                : formatDateToStandardFormat(new Date(bodyContent.effectiveDate ??
                    new Date())),
            strike: bodyContent.strike * 0.01 ||
                undefined,
            lowerCap: bodyContent.lowerCap *
                0.01 || undefined,
            upperCap: bodyContent.upperCap *
                0.01 || undefined,
            indicativeStrike: bodyContent
                .indicativeStrike * 0.01 || undefined,
            expiryDate: !bodyContent.expiryDate
                ? undefined
                : formatDateToStandardFormat(new Date(bodyContent.expiryDate ??
                    new Date())),
            quantity: bodyContent.vegaNotional,
            varianceUnits: undefined,
        };
        const response = !transmit
            ? await REST_API(restServer).OEMS.SAVE_ORDER("varSwap", newContent)
            : await REST_API(restServer).OEMS.SAVE_AND_TRANSMIT_ORDER("varSwap", newContent, { acceptComplianceWarnings });
        return response;
    }
    catch (err) {
        console.error("Error when submitting order", err);
        throw err;
    }
};
export const getCurrentOrder = (data, orderId, splitOrder) => {
    return {
        ...(splitOrder
            ? convertFormValueToVarSwapSplitOrder(data, orderId)
            : convertFormValueToVarSwapOrder(data)),
        id: orderId,
    };
};
