/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit, pick } from "lodash";
import * as React from "react";
import { Controller, useWatch, } from "react-hook-form";
// This import path is required for jest
import { ButtonGroupSelect } from "../control";
import { Checkbox } from "../inputs/Checkbox";
const CONTROLLER_PROP_KEYS = [
    "name",
    "rules",
    "onFocus",
    "defaultValue",
    "control",
];
function splitProps(props) {
    return [
        pick(props, CONTROLLER_PROP_KEYS),
        omit(props, CONTROLLER_PROP_KEYS),
    ];
}
export const ControlledInputBase = ({ render = () => React.createElement(React.Fragment, null), ...props }) => React.createElement(Controller, { ...props, render: (props, state) => render(props, state) });
export const ControlledEmpty = (props) => (React.createElement(ControlledInputBase, { ...props, render: () => React.createElement(React.Fragment, null) }));
export const ControlledCheckbox = ({ nullValue, onChange: onChangeExternal, infoText, watch, ...props }) => {
    const [controllerProps, inputProps] = splitProps(props);
    const watchedValue = useWatch({ name: props.name }) ?? nullValue;
    return (React.createElement(ControlledInputBase, { ...controllerProps, render: ({ ref: _ref, value, onChange, ...rest }) => (React.createElement(Checkbox, { ...rest, ...inputProps, infoText: infoText, checked: watch ? watchedValue : value ?? nullValue, onChange: (checked) => {
                onChange(checked);
                onChangeExternal?.(checked);
            } })) }));
};
export const ControlledButtonGroupSelect = ({ options, ...props }) => {
    const [controllerProps, inputProps] = splitProps(props);
    return (React.createElement(ControlledInputBase, { ...controllerProps, render: ({ ref: _ref, onChange, value, ...rest }) => {
            return (React.createElement(ButtonGroupSelect, { ...inputProps, ...rest, onSelect: (value) => {
                    onChange(value);
                }, selectedValue: value, options: options, neutralSeparatorColor: true, disableDeselect: true }));
        } }));
};
