/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMap, useMounted, useRefCallback } from "@enfusion-ui/hooks";
import { createTestId } from "@enfusion-ui/utils";
import { debounce, identity, pick, sortBy, uniqBy } from "lodash";
import * as React from "react";
import { EMPTY_ARRAY, EMPTY_RECORD } from "../../constants";
import { FileExplorerContext, useFileExplorerContext, } from "./context";
import FileNode from "./FileNode";
function getSubNodesByNodeEntry(node) {
    const subNodes = node.nodes || [];
    const children = subNodes.reduce((res, entry) => {
        return [...res, ...getSubNodesByNodeEntry(entry)];
    }, []);
    return [...subNodes, ...children];
}
const findNodes = (values, nodes, key = "id") => nodes.reduce((res, entry) => {
    const selectedNode = values.find((value) => entry[key] && entry[key] === value);
    if (selectedNode) {
        res.push(entry);
    }
    else if (entry.nodes) {
        const subNodes = findNodes(values, entry.nodes, key);
        if (subNodes) {
            // eslint-disable-next-line no-param-reassign
            res = [...res, ...subNodes];
        }
    }
    return res;
}, []);
const AsyncExplorer = React.memo(function AsyncExplorer({ node, open }) {
    const { retrieveNodes, setSubNodes, getSubNodes, AppLogging, FolderError, FolderLoadingIndicator, } = useFileExplorerContext();
    const isMounted = useMounted();
    const [retrievingNodes, setRetrievingNodes] = React.useState(false);
    const [childNodes, setChildNodesBase] = React.useState(() => {
        return node.nodes || null;
    });
    const [error, setError] = React.useState(false);
    const setChildNodes = useRefCallback((newNodes) => {
        if (isMounted()) {
            setChildNodesBase(newNodes);
        }
    }, [setChildNodesBase]);
    const getNodes = useRefCallback(async () => {
        if (retrieveNodes && !retrievingNodes) {
            setRetrievingNodes(true);
            try {
                const subNodes = await retrieveNodes(node);
                setChildNodes(subNodes);
                setSubNodes(node.path, subNodes || []);
            }
            catch (err) {
                AppLogging?.safeError("error getting nodes", err);
                setError(true);
            }
            finally {
                setRetrievingNodes(false);
            }
        }
    }, [node]);
    React.useEffect(() => {
        if (open && !error && childNodes === null) {
            if (retrieveNodes) {
                getNodes();
            }
            else {
                const subNodes = node.nodes ?? getSubNodes(node.path);
                if (subNodes)
                    setChildNodes(subNodes);
            }
        }
    }, [open, node, error, retrieveNodes]);
    return (React.createElement(React.Fragment, null,
        !retrievingNodes && error && !!FolderError ? (React.createElement(FolderError, { message: "Failed to get folder content" })) : null,
        retrievingNodes && !!FolderLoadingIndicator ? (React.createElement(FolderLoadingIndicator, null)) : null,
        !error && childNodes !== null && (React.createElement(FileExplorerCore, { nodes: childNodes, depth: (node.depth || 0) + 1 }))));
});
const createExplorer = (node) => React.memo(function AsyncExplorerMemo({ open }) {
    return React.createElement(AsyncExplorer, { node: node, open: open });
});
const DirectoryNode = React.memo(function DirectoryNode({ node, }) {
    const { onEntryClick, onEntryContext, checkSelections, defaultFoldersOpen, maxDepth = -1, setSubNodes, onFolderCheckChange, DirectoryAccordion, DirectoryAccordionProps, multiSelect, Checkbox, RightContent, RightContentProps, getFolderCheckedState, subscribeToSelectionChange, updateNodeSelectionState, } = useFileExplorerContext();
    const [checked, setChecked] = React.useState(() => getFolderCheckedState(node, true));
    const [open, setOpen] = React.useState();
    React.useEffect(() => {
        if (Array.isArray(node.nodes)) {
            setSubNodes(node.path, node.nodes);
        }
    }, []);
    React.useEffect(() => {
        return subscribeToSelectionChange(node.path, (selectionState) => {
            setChecked(selectionState);
            updateNodeSelectionState(node, selectionState === 1 ? true : false);
        });
    }, [node.id]);
    React.useEffect(() => {
        if (!multiSelect && checked > 0 && !open) {
            setOpen(true);
        }
    }, [checked]);
    const handleClick = useRefCallback((e) => {
        onEntryClick(node, e);
    }, [onEntryClick, node]);
    const handleContext = useRefCallback((e) => onEntryContext(node, e), [onEntryContext, node]);
    const handleCheckChange = useRefCallback((value) => {
        if (multiSelect) {
            onFolderCheckChange(node, value);
        }
    }, [onFolderCheckChange, JSON.stringify(node)]);
    const Explorer = React.useMemo(() => {
        if (typeof node.depth !== "number" || maxDepth <= 0) {
            return createExplorer(node);
        }
        if (typeof node.depth === "number" &&
            maxDepth !== -1 &&
            node.depth < maxDepth) {
            return createExplorer(node);
        }
    }, [node]);
    const leftContent = React.useMemo(() => checkSelections && !!Checkbox ? (React.createElement(Checkbox, { checked: checked === 0 ? false : checked === 1 ? true : null, onChange: handleCheckChange })) : undefined, [checkSelections, Checkbox, checked, handleCheckChange]);
    const rightContent = React.useMemo(() => !!RightContent ? (React.createElement(RightContent, { ...RightContentProps, node: node })) : undefined, [RightContent, RightContentProps, node]);
    return (React.createElement(DirectoryAccordion, { ...DirectoryAccordionProps, open: open, selected: checked > 0, title: node.name, path: node.path, leftContent: leftContent, rightContent: rightContent, onClick: handleClick, onContext: handleContext, contentComponent: Explorer, defaultOpen: defaultFoldersOpen || node.defaultOpen, node: node }));
});
const FileExplorerCore = React.forwardRef(function FileExplorerCore({ nodes, depth }, ref) {
    const { onEntryContext, sortNodes, filterNodes, MainContentContainer, MainContentContainerProps, } = useFileExplorerContext();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const sortedNodes = React.useMemo(() => sortNodes(nodes), [nodes]);
    const handleContext = useRefCallback((e) => onEntryContext(null, e), [onEntryContext]);
    const filteredNodes = React.useMemo(() => {
        if (filterNodes)
            return filterNodes(sortedNodes);
        return sortedNodes;
    }, [sortedNodes, filterNodes]);
    const content = React.useMemo(() => filteredNodes.map((node, idx) => !node.file ? (React.createElement(DirectoryNode, { key: node.id || idx, node: node, "data-testid": createTestId("directory-node") })) : (React.createElement(FileNode, { key: node.id || idx, node: node, fileIndex: idx }))), [filteredNodes]);
    return (React.createElement(MainContentContainer, { ...MainContentContainerProps, depth: depth, ref: ref, onContextMenu: handleContext }, content));
});
export const HeadlessFileExplorer = React.forwardRef(function FileExplorer({ nodes, filteredNodes, checkSelections = false, defaultFoldersOpen = false, maxDepth = -1, sortNodes = identity, retrieveNodes, onEntryClick, onEntryContext, filterNodes, multiSelect = false, onSelectionChange, values = EMPTY_ARRAY, AppLogging, MainContentContainer, MainContentContainerProps = EMPTY_RECORD, FileNode, FileNodeProps = EMPTY_RECORD, DirectoryAccordion, DirectoryAccordionProps = EMPTY_RECORD, Checkbox, RightContent, RightContentProps = EMPTY_RECORD, FolderError, FolderLoadingIndicator, selectionKey, allNodesSelected, selectedNodes: selectedNodesProp, }, ref) {
    //  isMounted = useMounted();
    const subscriptionsRef = React.useRef(new Map());
    const selectedNodes = React.useRef([]);
    const [subNodesRecord, { set: setSubNodesOfDirectory }] = useMap({});
    const [subNodesSelections, { set: updateSubNodeSelection, remove: removeSubNodeSelection, reset: resetSubNodeSelection, },] = useMap({});
    const setSubNodesSelection = useRefCallback((key, value) => {
        if (value === 0) {
            removeSubNodeSelection(key);
        }
        else {
            updateSubNodeSelection(key, value);
        }
    }, [removeSubNodeSelection, updateSubNodeSelection]);
    const setSubNodes = useRefCallback((key, value) => {
        if (filteredNodes?.length) {
            const directoryNodes = findNodes([key], nodes, "path");
            if (directoryNodes?.length) {
                setSubNodesOfDirectory(key, directoryNodes[0]?.nodes ?? []);
            }
        }
        else {
            setSubNodesOfDirectory(key, value);
        }
    }, []);
    const getSubNodes = useRefCallback((key) => subNodesRecord.current[key], [subNodesRecord]);
    const getAllParentNodes = useRefCallback((key, level = 0) => {
        const split = key.split("/").slice(0, -1);
        if (split.length === 0)
            return [];
        if (split.length === 1) {
            let parentKey = null;
            if (split[0].length) {
                parentKey = split[0];
            }
            else {
                parentKey = level === 0 ? null : key;
            }
            return parentKey ? nodes.filter((i) => i.path === parentKey) : [];
        }
        const parentKey = split.join("/");
        if (level !== 0) {
            const parentSubNodes = getSubNodes(parentKey) ??
                findNodes([parentKey], nodes, "path")?.[0]?.nodes ??
                [];
            const parentNode = parentSubNodes?.find((i) => i.path === key);
            return [...(getAllParentNodes(parentKey, level + 1) ?? []), parentNode];
        }
        return getAllParentNodes(parentKey, level + 1).filter(Boolean);
    }, [nodes]);
    const getParentKey = useRefCallback((key) => {
        const split = key.split("/").slice(0, -1);
        let parentKey = "";
        if (split.length === 1) {
            parentKey = split[0].length === 0 ? key : split[0];
        }
        parentKey = split.join("/");
        return parentKey;
    }, []);
    const getAllSubNodes = useRefCallback((key) => {
        const subNodes = subNodesRecord.current[key];
        if (!subNodes)
            return undefined;
        return [
            ...subNodes,
            ...subNodes.reduce((res, subNode) => {
                const subSubNodes = getAllSubNodes(subNode.path) ?? getSubNodesByNodeEntry(subNode);
                if (subSubNodes) {
                    return [...res, ...subSubNodes];
                }
                return res;
            }, []),
        ];
    }, [subNodesRecord, getSubNodesByNodeEntry]);
    const handleCallbackChange = useRefCallback(debounce((newSelectedNodes) => {
        onSelectionChange?.(newSelectedNodes);
    }, 600), [onSelectionChange]);
    const updateParentNodesState = useRefCallback((newSelectedNodes, selected, ignoreCurrent = false) => {
        const parentNodes = newSelectedNodes?.flatMap((node) => getAllParentNodes(node.path));
        const uniqNodes = uniqBy(parentNodes, (node) => node.path);
        const sortedNodes = sortBy([...uniqNodes], [(o) => o.path.length]).reverse();
        if (!ignoreCurrent) {
            const selectedNodesEntries = newSelectedNodes.reduce((res, i) => {
                const callbacks = subscriptionsRef.current.get(i.path);
                if (callbacks) {
                    res = [...res, ...callbacks];
                }
                return res;
            }, []);
            for (const selectedNodeEntry of selectedNodesEntries) {
                selectedNodeEntry?.(selected ? 1 : 0);
            }
        }
        sortedNodes?.forEach((i) => {
            getFolderCheckedState(i);
            const callbacks = subscriptionsRef.current.get(i.path);
            if (callbacks) {
                const selectionState = subNodesSelections.current[i.path];
                for (const callback of callbacks) {
                    callback?.(selectionState ?? 0);
                }
            }
        });
    }, [subscriptionsRef, subNodesSelections]);
    const updateChildNodesState = useRefCallback((newSelectedNodes, selected) => {
        const subscriptionsKeys = [...subscriptionsRef.current.keys()];
        const matchedSubscriptionKeys = newSelectedNodes.reduce((res, x) => {
            if (!selected) {
                const newSelectedNodesState = selectedNodes.current?.filter((selectedNode) => {
                    if (selectedNode.path.startsWith(`${x.path}/`)) {
                        setSubNodesSelection(selectedNode.path, 0);
                        return false;
                    }
                    return true;
                });
                selectedNodes.current = newSelectedNodesState;
            }
            const keys = subscriptionsKeys?.filter((i) => i === x.path || i.startsWith(`${x.path}/`));
            if (keys)
                res = [...res, ...keys];
            return res;
        }, []);
        for (const key of matchedSubscriptionKeys) {
            const entries = subscriptionsRef.current.get(key) || [];
            setSubNodesSelection(key, selected ? 1 : 0);
            for (const entry of entries) {
                entry(selected ? 1 : 0);
            }
        }
    }, [subscriptionsRef, selectedNodes]);
    const updateNodesSelections = useRefCallback((newSelectedNodes, selected) => {
        const [fileNodes, directoryNodes] = newSelectedNodes?.reduce((res, selectedNode) => {
            updateNodeSelectionState(selectedNode, selected);
            setSubNodesSelection(selectedNode.path, selected ? 1 : 0);
            if (selectedNode.file) {
                res[0].push(selectedNode);
            }
            else {
                res[1].push(selectedNode);
            }
            return res;
        }, [[], []]);
        if (fileNodes.length) {
            updateParentNodesState(fileNodes, selected);
        }
        if (directoryNodes.length) {
            updateChildNodesState(directoryNodes, selected);
            updateParentNodesState(directoryNodes, selected, true);
        }
        // send back nodes having selection state as 1.
        handleCallbackChange(uniqBy(selectedNodes.current, (node) => node.path));
    }, []);
    const handleChangeSelection = useRefCallback((add, remove) => {
        if (remove?.length) {
            updateNodesSelections(remove, false);
        }
        if (add?.length) {
            updateNodesSelections(add, true);
        }
    }, [selectedNodes, subNodesSelections]);
    const handleEntryClick = useRefCallback((node, e = {}) => {
        const modifiers = {
            ...pick(e, ["ctrlKey", "shiftKey", "altKey", "metaKey"]),
        };
        if (checkSelections)
            modifiers.ctrlKey = true;
        if (node.file && modifiers.ctrlKey) {
            const selectionState = subNodesSelections.current[node.path] ?? 0;
            if (selectionState === 0) {
                handleChangeSelection([node], multiSelect
                    ? null
                    : selectedNodes.current?.filter((node) => node.file) ?? null);
            }
            else {
                handleChangeSelection(null, [node]);
            }
        }
        if (!modifiers.ctrlKey) {
            onEntryClick?.(node, modifiers);
        }
    }, [selectedNodes, checkSelections, onEntryClick]);
    const handleContextClick = useRefCallback((node, e) => {
        // 2 is the right click so context is only right click
        if (e?.button === 2 && onEntryContext) {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            onEntryContext({
                node,
                clientX: e?.nativeEvent?.clientX,
                clientY: e?.nativeEvent?.clientY,
                modifiers: pick(e, ["ctrlKey", "shiftKey", "altKey", "metaKey"]),
                nodes: selectedNodes.current,
            });
        }
        else if (e?.button !== 2 && !!node) {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            handleEntryClick(node, e);
        }
    }, [onEntryContext, handleEntryClick, selectedNodes]);
    const handleFolderCheckChange = useRefCallback((node, value) => {
        handleChangeSelection(value ? [node] : null, value ? null : [node]);
    }, [handleChangeSelection]);
    const handleFileCheckChange = useRefCallback((node, value) => {
        if (value) {
            handleChangeSelection([node], multiSelect
                ? null
                : selectedNodes.current?.filter((node) => node.file) ?? null);
        }
        else {
            handleChangeSelection(null, [node]);
        }
    }, [handleChangeSelection]);
    const handleSelectChangeSubscriptions = useRefCallback((path, cb) => {
        let res = subscriptionsRef.current.get(path) || [];
        res = [...res.filter((i) => i !== cb), cb];
        subscriptionsRef.current.set(path, res);
        return () => {
            let res = subscriptionsRef.current.get(path) || [];
            res = [...res.filter((i) => i !== cb)];
            if (res.length === 0) {
                try {
                    subscriptionsRef.current.delete(path);
                }
                catch (errIgnored) {
                    // noop
                }
            }
            else {
                subscriptionsRef.current.set(path, res);
            }
        };
    }, []);
    const getFolderCheckedState = useRefCallback((node, considerParentSelectionState = false) => {
        let checkedState = 0;
        if (considerParentSelectionState) {
            const parentKey = getParentKey(node.path);
            if (subNodesSelections.current[parentKey] !== undefined) {
                checkedState = [1, 0].includes(subNodesSelections.current[parentKey])
                    ? subNodesSelections.current[parentKey]
                    : subNodesSelections.current[node.path] ?? 0;
            }
        }
        else {
            checkedState = getFolderCalculatedCheckedState(node) ?? 0;
        }
        updateNodeSelectionState(node, checkedState === 1 ? true : false);
        setSubNodesSelection(node.path, checkedState);
        return checkedState;
    }, [subNodesSelections]);
    const getFolderCalculatedCheckedState = useRefCallback((node) => {
        const subNodes = node.nodes ||
            getSubNodes(node.path) ||
            EMPTY_ARRAY;
        if (subNodes.length === 0)
            return 0;
        if (subNodes.every((e) => subNodesSelections.current[e.path] === 1))
            return 1;
        if (subNodes.some((e) => [1, 2].includes(subNodesSelections.current[e.path])))
            return 2;
        return 0;
    }, [subNodesSelections]);
    const getFileSelectionState = useRefCallback((node, considerParentSelectionState = false) => {
        let selectionState = false;
        if (considerParentSelectionState) {
            const parentKey = getParentKey(node.path);
            if (parentKey) {
                if (subNodesSelections.current[parentKey] === 2) {
                    selectionState = subNodesSelections.current[node.path] === 1;
                }
                else {
                    selectionState = subNodesSelections.current[parentKey] === 1;
                }
            }
        }
        else {
            selectionState = subNodesSelections.current[node.path] === 1;
        }
        setSubNodesSelection(node.path, selectionState ? 1 : 0);
        updateNodeSelectionState(node, selectionState);
        return selectionState;
    }, [subNodesSelections]);
    const updateNodeSelectionState = useRefCallback((node, selected) => {
        if (selected) {
            selectedNodes.current.push(node);
        }
        else {
            selectedNodes.current = [
                ...selectedNodes.current?.filter((i) => node.id !== i.id),
            ];
        }
    }, [selectedNodes]);
    const getChildNodeSelectionState = useRefCallback((childNodes, nodeList) => childNodes.some((i) => (nodeList || selectedNodes.current).some((selectedNode) => selectedNode.id === i.id)), [selectedNodes]);
    const providerState = React.useMemo(() => {
        const state = {
            sortNodes,
            retrieveNodes,
            onEntryClick: handleEntryClick,
            onEntryContext: handleContextClick,
            onFolderCheckChange: handleFolderCheckChange,
            onFileCheckChange: handleFileCheckChange,
            subscribeToSelectionChange: handleSelectChangeSubscriptions,
            getFolderCheckedState,
            getFileSelectionState,
            getChildNodeSelectionState,
            checkSelections,
            defaultFoldersOpen,
            maxDepth,
            multiSelect,
            changeSelection: handleChangeSelection,
            getSubNodes,
            setSubNodes,
            getAllSubNodes,
            filterNodes,
            AppLogging,
            MainContentContainer,
            MainContentContainerProps,
            FileNode,
            FileNodeProps,
            DirectoryAccordion,
            DirectoryAccordionProps,
            Checkbox,
            RightContent,
            RightContentProps,
            FolderError,
            FolderLoadingIndicator,
            updateNodeSelectionState,
        };
        return state;
    }, [
        sortNodes,
        retrieveNodes,
        handleEntryClick,
        handleContextClick,
        handleChangeSelection,
        handleFolderCheckChange,
        getSubNodes,
        getAllSubNodes,
        setSubNodes,
        getChildNodeSelectionState,
        checkSelections,
        defaultFoldersOpen,
        maxDepth,
        filterNodes,
        AppLogging,
        MainContentContainer,
        FileNode,
        FileNodeProps,
        DirectoryAccordion,
        DirectoryAccordionProps,
        Checkbox,
        RightContent,
        RightContentProps,
        FolderError,
        FolderLoadingIndicator,
        MainContentContainerProps,
        handleSelectChangeSubscriptions,
        getFolderCheckedState,
        getFileSelectionState,
        updateNodeSelectionState,
    ]);
    const clearSelections = useRefCallback(() => {
        if (selectedNodes.current?.length) {
            selectedNodes.current = [];
            resetSubNodeSelection();
            handleChangeSelection(null, nodes);
        }
    }, []);
    // We should pass in either the nodeIds or the nodes itself not both
    // Pass nodes where there is duplication of ids else selection is gonna effect
    React.useEffect(() => {
        if (values) {
            const defaultSelectedNodes = findNodes(values, nodes, selectionKey);
            if (defaultSelectedNodes?.length) {
                if (multiSelect) {
                    clearSelections();
                    handleChangeSelection(defaultSelectedNodes, null);
                }
                else {
                    handleChangeSelection(defaultSelectedNodes, selectedNodes.current?.filter((node) => node.file) ?? null);
                }
            }
            else {
                //clear selection
                clearSelections();
            }
        }
    }, [values]);
    React.useEffect(() => {
        if (allNodesSelected) {
            handleChangeSelection(nodes, null);
        }
    }, [allNodesSelected]);
    React.useEffect(() => {
        if (selectedNodesProp) {
            if (selectedNodesProp?.length) {
                if (multiSelect) {
                    clearSelections();
                    handleChangeSelection(selectedNodesProp, null);
                }
                else {
                    handleChangeSelection(selectedNodesProp, selectedNodes.current?.filter((node) => node.file) ?? null);
                }
            }
            else {
                //clear selection
                clearSelections();
            }
        }
    }, [selectedNodesProp]);
    return (React.createElement(FileExplorerContext.Provider, { value: providerState },
        React.createElement(FileExplorerCore, { nodes: filteredNodes?.length ? filteredNodes : nodes, ref: ref, depth: 0 })));
});
