/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TimeProxy } from "./timeProxy";
const getKey = (key) => (Array.isArray(key) ? key[0] : key);
export class SimpleCache {
    store = new Map();
    has = (key) => this.store.has(getKey(key));
    get = (key) => this.store.get(getKey(key));
    set = (key, value) => this.store.set(getKey(key), value);
    delete = (key) => this.store.delete(getKey(key));
    clear = () => this.store.clear();
}
const defaultMaxAge = TimeProxy.minutes.one.asMilliseconds;
export class BasicLRUCache {
    maxAge = defaultMaxAge;
    constructor(maxAge = defaultMaxAge) {
        this.maxAge = maxAge;
    }
    store = new Map();
    has = (key) => {
        const k = getKey(key);
        this.store.forEach((val, ek) => {
            if (ek !== k && Date.now() - val.d >= this.maxAge)
                this.store.delete(ek);
        });
        return this.store.has(k);
    };
    get = (key) => this.store.get(getKey(key)).v;
    set = (key, value) => {
        this.store.set(getKey(key), { d: Date.now(), v: value });
    };
    delete = (key) => {
        this.store.delete(getKey(key));
    };
    clear = () => {
        this.store.clear();
    };
}
const DEFAULT_KEY = "¡DEFAULT¡";
const getKeyArray = (key) => Array.isArray(key) ? key : [key, DEFAULT_KEY];
export class KeyedLRUCache {
    store = new Map();
    maxAge = defaultMaxAge;
    constructor(maxAge = defaultMaxAge) {
        this.maxAge = maxAge;
    }
    has = (key) => {
        const k = getKeyArray(key);
        this.store.forEach((val, ek) => {
            if (ek !== k[0] && Date.now() - val.d >= this.maxAge)
                this.store.delete(ek);
        });
        const hasCore = this.store.has(k[0]);
        let has = hasCore && this.store.get(k[0]).s.has(k[1]);
        if (hasCore) {
            const map = this.store.get(k[0]);
            map.s.forEach((val, key) => {
                if (key !== k[1] && Date.now() - val.d >= this.maxAge)
                    map.s.delete(key);
            });
            has = map.s.has(k[1]);
            if (has)
                map.d = Date.now();
            this.store.set(k[0], map);
        }
        return has;
    };
    get = (key) => {
        const k = getKeyArray(key);
        const value = this.store.get(k[0]).s.get(k[1]).v;
        this.set(k, value);
        return value;
    };
    set = (key, value) => {
        const k = getKeyArray(key);
        if (!this.store.has(k[0]))
            this.store.set(k[0], {
                d: Date.now(),
                s: new Map(),
            });
        const map = this.store.get(k[0]);
        map.d = Date.now();
        map.s.set(k[1], { d: Date.now(), v: value });
        this.store.set(k[0], map);
    };
    delete = (key) => {
        const k = getKeyArray(key);
        if (k[1] === DEFAULT_KEY)
            this.store.delete(k[0]);
        else
            this.store.get(k[0])?.s.delete(k[1]);
    };
    clear = () => {
        this.store.clear();
    };
}
