import { FINANCIAL_SUBTYPE_OPTIONS } from "@enfusion-ui/types";
import React from "react";
export const useHoldingIdParser = (pid) => {
    let account;
    let bookId;
    let instrument;
    let trs;
    let ignoreBook;
    let dealId;
    let positionBlock;
    const components = pid.split("/");
    if (components.length > 5) {
        account = parseInt(components[0].split("-")[1]);
        bookId = parseInt(components[1]);
        instrument = parseInt(components[2]);
        trs = parseInt(components[3]);
        const ignoreBookIdx = ["true", "false"].includes(components[4]) ? 4 : 5;
        ignoreBook = components[ignoreBookIdx];
        positionBlock = ignoreBookIdx === 5 ? components[4] : undefined;
        dealId =
            components.length > ignoreBookIdx + 1 &&
                !FINANCIAL_SUBTYPE_OPTIONS.some(({ value }) => components[ignoreBookIdx + 1] === value)
                ? components[ignoreBookIdx + 1]
                : undefined;
    }
    return React.useMemo(() => ({
        account,
        bookId,
        instrument,
        trs,
        ignoreBook,
        dealId,
        positionBlock,
    }), [account, bookId, instrument, trs, ignoreBook, dealId, positionBlock]);
};
