import { parseISO } from "date-fns";
import { omit, words } from "lodash";
import { formatCurrency, formatDateTimeFormat, formatNumber, formatPercentage, formatPositionIdentifierScenarioCellValue, } from "./formatters";
function formatNumberValue(value, fractionDigits, numberStyle, currency, options) {
    const reduce = options?.numberFormat?.reduce ?? options?.numberReductionFormat;
    const rest = omit(options?.numberFormat || {}, ["reduce", "fractionDigits"]);
    if (currency || numberStyle === "Currency")
        return formatCurrency(value, currency ?? "USD", {
            fractionDigits,
            reduce,
            ...rest,
        });
    if (numberStyle === "Percent")
        return formatPercentage(value, fractionDigits, rest.formatNegative, rest.forceAverage, reduce);
    return formatNumber(value, { fractionDigits, reduce, ...rest });
}
export function formatReportValue(data, column, options) {
    if (!data || data.value === null || typeof data.value === "undefined")
        return "";
    const { value } = data;
    const isId = words(column.displayName?.toLocaleLowerCase()).includes("id");
    if (isId)
        return value;
    const { numberStyle } = column;
    switch (column.dataType) {
        case "Date": {
            const d = parseISO(value);
            const r = [d.getFullYear(), d.getMonth(), d.getDate()];
            if (r[0] === 9999 && r[1] === 0 && r[2] === 1)
                return "TBD";
            const { dateTimeFormat } = column;
            return formatDateTimeFormat(value, dateTimeFormat) ?? "";
        }
        case "Double": {
            const { decimalPlaces } = column;
            let fractionDigits = decimalPlaces;
            if (typeof options?.numberFormat?.fractionDigits !== "undefined")
                fractionDigits = options?.numberFormat?.fractionDigits;
            return formatNumberValue(value, fractionDigits, numberStyle, data.currency);
        }
        case "PositionIdentifierScenario":
            return formatPositionIdentifierScenarioCellValue(value);
        case "Boolean":
            return value ? "True" : "False";
        case "Integer":
            return formatNumberValue(value, 0, numberStyle, data.currency);
        case "HTMLLink":
            return data;
        case "String":
            return value;
        default:
            return `${value}`;
    }
}
