/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from "query-string";
import { fillVoiceOrderFactory, getOrderFactory, getSplitOrderFactory, getUrlOrderType, saveAndTransmitOrderFactory, saveOrderFactory, } from "./utils/oems";
import { varReplace } from "./utils/varReplace";
const baseUrl = "/internal/api/oms/v1";
export const calls = {
    TRANSMIT_ORDER: {
        url: `${baseUrl}/orders/transmit/{orderId}`,
        method: "POST",
        body: {},
        urlParams: {
            orderId: { defaultValue: 1 },
        },
        query: {
            transmitWithWarnings: { defaultValue: false },
        },
    },
    VALIDATE_ORDER_ACTION: {
        url: `${baseUrl}/orders/validate/actions`,
        method: "POST",
        body: {},
    },
    CANCEL_ORDER: {
        url: `${baseUrl}/orders/cancel/{orderId}`,
        method: "POST",
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_FILLS: {
        url: `${baseUrl}/orders/fills/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_CALCULATED_VALUE: {
        url: `${baseUrl}/calculator`,
        method: "POST",
        body: {
            allocate: false,
            target: 100,
            targetType: "FinalQuantity",
            order: {},
        },
    },
    GET_LOCATES: {
        url: `${baseUrl}/orders/locates/{instrumentId}`,
        urlParams: {
            instrumentId: { defaultValue: 1 },
        },
    },
    GET_LOCATE_BROKERS: { url: `${baseUrl}/orders/locates/brokers` },
    GET_LOCATE_BROKERS_CUSTOM: {
        url: `${baseUrl}/orders/locates/brokers/custom`,
    },
    GET_ALL_LOCATES: { url: `${baseUrl}/orders/locates/all` },
    GET_ALL_POSITIONS: { url: "/internal/api/reports/oms/positions" },
    GET_OEMS_POSITIONS: {
        url: "/internal/api/reports/oms/position",
        method: "POST",
        body: {
            instrumentId: 1,
            activeOnly: false,
            positionDate: { dateSelectionType: "AsOfDate", asOfDate: "2024/01/01" },
        },
    },
    GET_LIMIT_PRICE: {
        url: `${baseUrl}/orders/limit/quote`,
        query: {
            instrumentId: { optional: false, defaultValue: 1 },
            orderSide: { optional: false, defaultValue: "" },
            financialSubType: {},
        },
    },
    GET_PREFERRED_ALLOCATION_SCHEMES: {
        url: `${baseUrl}/allocation/schemes/preferred`,
    },
    GET_ALL_ALLOCATION_SCHEMES: { url: `${baseUrl}/allocation/schemes` },
    GET_ALLOCATION_SCHEME: {
        url: `${baseUrl}/allocation/schemes/apply`,
        query: {
            schemeId: { optional: false, defaultValue: 1 },
        },
    },
    APPLY_ALLOCATION_TEMPLATE: { url: `${baseUrl}/allocation/templates/apply` },
    APPLY_SWAP_CHECK: { url: `${baseUrl}/order/apply-swap-check` },
    GET_PREFERRED_EXECUTION_SCHEMES: {
        url: `${baseUrl}/execution/schemes/preferred`,
        query: {
            instrumentId: { optional: false, defaultValue: 1 },
            financialType: {},
            isSwap: { defaultValue: false },
            tradingDeskId: {},
        },
    },
    GET_ALL_EXECUTION_SCHEMES: {
        url: `${baseUrl}/execution/schemes`,
        query: {
            instrumentId: { optional: false, defaultValue: 1 },
            financialType: {},
            isSwap: { defaultValue: false },
            tradingDeskId: {},
        },
    },
    GET_COMPLIANCE: {
        url: `${baseUrl}/orders/compliance/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    RUN_COMPLIANCE_BULK: {
        url: `${baseUrl}/orders/compliance/run`,
        method: "POST",
        query: {
            orderIds: { optional: false, defaultValue: "1,2" },
        },
    },
    VALIDATE_CDX_ORDERS_ACTIONS: {
        url: `${baseUrl}/orders/cdx/validate/actions`,
        method: "POST",
        body: {},
    },
    SAVE_CDX_ORDERS: {
        url: `${baseUrl}/orders/cdx/save`,
        method: "POST",
        body: {},
        query: {
            acceptValidationWarnings: { defaultValue: false },
            acceptComplianceWarnings: { defaultValue: false },
        },
    },
    SAVE_TRANSMIT_CDX_ORDERS: {
        url: `${baseUrl}/orders/cdx/save/transmit`,
        method: "POST",
        body: {},
        query: {
            acceptValidationWarnings: { defaultValue: false },
            acceptComplianceWarnings: { defaultValue: false },
        },
    },
    GET_ORDERS_CDX: {
        url: `${baseUrl}/orders/cdx/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_ORDERS_CDX_DEFAULT: { url: `${baseUrl}/orders/cdx/default` },
    GET_INSTRUMENT_SNAPSHOT: {
        url: `${baseUrl}/instrument/snapshot/{instrumentId}`,
        urlParams: {
            instrumentId: { defaultValue: 1 },
        },
    },
    GET_COUNTERPARTIES_EXECUTION: {
        url: `${baseUrl}/execution/counterparties`,
        query: {
            instrumentId: { optional: false, defaultValue: 1 },
        },
    },
    SAVE_BULK_ORDERS: {
        url: `${baseUrl}/orders/bulk/save`,
        method: "POST",
        body: {},
        query: {
            acceptValidationWarnings: { defaultValue: false },
            acceptComplianceWarnings: { defaultValue: false },
            fastSave: { defaultValue: false },
            summarizedResponse: { defaultValue: false },
        },
    },
    RUN_COMPLIANCE: {
        url: `${baseUrl}/orders/compliance/run/{orderId}`,
        method: "POST",
        body: {},
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    UPDATE_COMPLIANCE_STATUS: { url: `${baseUrl}/orders/compliance/status` },
    GET_ACCOUNTS: { url: `${baseUrl}/accounts` },
    GET_ALLOCATIONS_TEMPLATES: { url: `${baseUrl}/allocation/templates` },
    GET_TABLE_METADATA: { url: `${baseUrl}/orders/table/metadata` },
    GET_ORDER_SUBMISSIONS: {
        url: `${baseUrl}/orders/submissions/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_ORDER_ROUTING: {
        url: `${baseUrl}/orders/routing/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_COMPLIANCE_TEMPLATES: { url: `${baseUrl}/orders/compliance/templates` },
    GET_COMPLIANCE_RULES: { url: `${baseUrl}/orders/compliance/rules` },
    GET_QUOTES: {
        url: `${baseUrl}/orders/quotes/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_OMS_PREFERENCES: { url: `${baseUrl}/prefs` },
    GET_OMS_PREFERENCES_COLOR: { url: `${baseUrl}/prefs/color` },
    GET_OMS_SETTINGS: { url: `${baseUrl}/settings` },
    IS_ORDER_TRANSMITTABLE: {
        url: `${baseUrl}/orders/transmittable`,
        method: "POST",
        body: {},
    },
    SAVE_ORDER: {
        url: `${baseUrl}/orders/save`,
        method: "POST",
        body: {},
        query: {
            acceptValidationWarnings: { defaultValue: false },
            acceptComplianceWarnings: { defaultValue: false },
        },
    },
    SAVE_TRANSMIT_ORDER: {
        url: `${baseUrl}/orders/save/transmit`,
        method: "POST",
        body: {},
        query: {
            acceptValidationWarnings: { defaultValue: false },
            acceptComplianceWarnings: { defaultValue: false },
        },
    },
    FX_VALIDATE_ORDER_ACTIONS: {
        url: `${baseUrl}/orders/fx/validate/actions`,
        method: "POST",
        body: {},
    },
    FX_SAVE_ORDER: {
        url: `${baseUrl}/orders/fx/save`,
        method: "POST",
        body: {},
    },
    FX_SAVE_TRANSMIT_ORDER: {
        url: `${baseUrl}/orders/fx/save/transmit`,
        method: "POST",
        body: {},
        query: {
            acceptValidationWarnings: { defaultValue: false },
            acceptComplianceWarnings: { defaultValue: false },
        },
    },
    FX_ORDERS_DATES_DEFAULT: {
        url: `${baseUrl}/orders/fx/dates/default`,
        method: "POST",
        body: {},
    },
    FILL_LISTED_ORDERS: {
        url: `${baseUrl}/orders/fill/listed`,
        method: "POST",
        body: {},
        query: {
            acceptWarnings: { defaultValue: false },
        },
    },
    FX_FILL_LISTED_ORDERS: {
        url: `${baseUrl}/orders/fill/fx`,
        method: "POST",
        body: {},
        query: {
            acceptWarnings: { defaultValue: false },
        },
    },
    FILL_ORDERS_FUTURESPREAD: {
        url: `${baseUrl}/orders/fill/futurespread`,
        method: "POST",
        body: {},
        query: {
            acceptWarnings: { defaultValue: false },
        },
    },
    FILL_ORDERS_CDS: {
        url: `${baseUrl}/orders/fill/cds`,
        method: "POST",
        body: {},
        query: {
            acceptWarnings: { defaultValue: false },
        },
    },
    CREATE_CROSS_ORDER: {
        url: `${baseUrl}/orders/cross/crossOrders`,
        method: "POST",
        body: {},
    },
    GET_CROSS_ORDERS_ELIGIBLE: {
        url: `${baseUrl}/orders/cross/eligibleOrders/{instrumentId}`,
        urlParams: {
            instrumentId: { defaultValue: 1 },
        },
        query: {
            includeWarnings: { defaultValue: false },
        },
    },
    GET_ELECTRONIC_ROUTING_TARGETS: {
        url: `${baseUrl}/execution/targets`,
        query: {
            financialType: {},
            instrumentId: { optional: false, defaultValue: 1 },
            isSwap: { defaultValue: false },
        },
    },
    GET_ELECTRONIC_ROUTING_BROKER_ACCOUNTS: {
        url: `${baseUrl}/execution/brokeraccounts`,
        query: {
            targetCompId: { optional: false, defaultValue: "" },
            destination: { optional: false, defaultValue: "" },
        },
    },
    GET_ELECTRONIC_ROUTING_STRATEGY: {
        url: `${baseUrl}/execution/strategies`,
        query: {
            targetCompId: { optional: false, defaultValue: "" },
            destination: { optional: false, defaultValue: "" },
            instrumentId: { optional: false, defaultValue: 1 },
            financialSubType: {},
        },
    },
    CALCULATE_VARIANCE_NOTIONAL: { url: `${baseUrl}/varianceSwapCalculator` },
    GET_ELECTRONIC_ROUTING_DESTINATIONS: {
        url: `${baseUrl}/execution/destinations`,
    },
    GET_ORDER_COLUMNS: {
        url: `${baseUrl}/orders/views`,
        query: {
            mobile: { defaultValue: false },
        },
    },
    SAVE_ORDER_COLUMNS: {
        url: `${baseUrl}/orders/views/save`,
        query: {
            mobile: { defaultValue: false },
        },
    },
    GET_QUICK_ENTRY_CALCULATION: {
        url: `${baseUrl}/orders/quickentry`,
        method: "POST",
        body: {},
    },
    GET_ALLOCATION_REASON_TEMPLATES: {
        url: `${baseUrl}/allocation/reason/templates`,
    },
    GET_TRADE_REASON_TEMPLATES: { url: `${baseUrl}/trade/reason/templates` },
    GET_DARK_POOLS: { url: `${baseUrl}/darkpools` },
    GET_OPTIMIZE_TYPES: { url: `${baseUrl}/allocation/optimize/types` },
    GET_DAY_COUNTS: { url: `${baseUrl}/daycounts` },
    GET_OPTIMIZED_ALLOCATION_QTY: {
        url: `${baseUrl}/allocation/optimize`,
        method: "POST",
        body: {
            allocations: [],
            instrumentId: 1,
            totalQuantity: 100,
            optimizationType: null,
        },
    },
    GET_OPTIMIZED_ALLOCATION: {
        url: `${baseUrl}/allocation/optimize/{orderId}`,
        urlParams: {
            orderId: { defaultValue: 1 },
        },
    },
    GET_ALLOCATION_DETAILS: {
        url: `${baseUrl}/allocation/details`,
        query: {
            orderId: { optional: false, defaultValue: 0 },
            fundMode: { defaultValue: false },
        },
    },
    VALIDATE_STRATEGY_PARAMS: { url: `${baseUrl}/orders/strategy/validate` },
    GET_DEFAULT_ORDER: {
        url: `${baseUrl}/orders{orderType}/default`,
        urlParams: {
            orderType: {
                defaultValue: "",
                options: ["", "/fx", "/irs", "/cdx", "/varSwap"],
            },
        },
        query: {
            instrumentId: { optional: false, defaultValue: 1 },
        },
    },
};
export default (callCreator) => {
    return {
        FILL_VOICE_ORDER: fillVoiceOrderFactory(callCreator),
        SAVE_AND_TRANSMIT_ORDER: saveAndTransmitOrderFactory(callCreator),
        SAVE_ORDER: saveOrderFactory(callCreator),
        GET_SPLIT_ORDER: getSplitOrderFactory(callCreator),
        GET_ORDER: getOrderFactory(callCreator),
        TRANSMIT_ORDER: callCreator((orderId, transmitWithWarnings = false) => [
            queryString.stringifyUrl({
                url: varReplace(calls.TRANSMIT_ORDER.url, { orderId }),
                query: { transmitWithWarnings },
            }),
            { method: "POST" },
        ]),
        CANCEL_ORDER: callCreator((orderId) => [
            varReplace(calls.CANCEL_ORDER.url, { orderId }),
            { method: "POST" },
        ]),
        GET_FILLS: callCreator((orderId) => varReplace(calls.GET_FILLS.url, { orderId })),
        GET_CALCULATED_VALUE: callCreator((body) => [calls.GET_CALCULATED_VALUE.url, { body }]),
        GET_LOCATES: callCreator((instrumentId) => varReplace(calls.GET_LOCATES.url, { instrumentId })),
        GET_LOCATE_BROKERS: callCreator(() => calls.GET_LOCATE_BROKERS.url),
        GET_LOCATE_BROKERS_CUSTOM: callCreator(() => calls.GET_LOCATE_BROKERS_CUSTOM.url),
        GET_ALL_LOCATES: callCreator(() => calls.GET_ALL_LOCATES.url),
        GET_ALL_POSITIONS: callCreator(() => calls.GET_ALL_POSITIONS.url),
        GET_LIMIT_PRICE: callCreator((instrumentId, orderSide, financialSubType) => queryString.stringifyUrl({
            url: calls.GET_LIMIT_PRICE.url,
            query: { instrumentId, orderSide, financialSubType },
        })),
        GET_OEMS_POSITIONS: callCreator((instrumentId, activeOnly, positionDate) => [
            calls.GET_OEMS_POSITIONS.url,
            {
                body: { instrumentId, activeOnly, positionDate },
            },
        ]),
        GET_PREFERRED_ALLOCATION_SCHEMES: callCreator(() => calls.GET_PREFERRED_ALLOCATION_SCHEMES.url),
        GET_ALL_ALLOCATION_SCHEMES: callCreator(() => calls.GET_ALL_ALLOCATION_SCHEMES.url),
        GET_ALLOCATION_SCHEME: callCreator((schemeId, body) => [
            queryString.stringifyUrl({
                url: calls.GET_ALLOCATION_SCHEME.url,
                query: { schemeId },
            }),
            { body },
        ]),
        APPLY_ALLOCATION_TEMPLATE: callCreator((body) => [calls.APPLY_ALLOCATION_TEMPLATE.url, { body }]),
        APPLY_SWAP_CHECK: callCreator((body) => [calls.APPLY_SWAP_CHECK.url, { body }]),
        GET_PREFERRED_EXECUTION_SCHEMES: callCreator((instrumentId, financialType, isSwap, tradingDeskId) => queryString.stringifyUrl({
            url: calls.GET_PREFERRED_EXECUTION_SCHEMES.url,
            query: {
                instrumentId,
                financialType,
                isSwap,
                tradingDeskId,
            },
        })),
        GET_ALL_EXECUTION_SCHEMES: callCreator((instrumentId, financialType, isSwap, tradingDeskId) => queryString.stringifyUrl({
            url: calls.GET_ALL_EXECUTION_SCHEMES.url,
            query: {
                instrumentId,
                financialType,
                isSwap,
                tradingDeskId,
            },
        })),
        GET_DEFAULT_ORDER: callCreator((instrumentId, orderType = "equity") => queryString.stringifyUrl({
            url: varReplace(calls.GET_DEFAULT_ORDER.url, {
                orderType: getUrlOrderType(orderType),
            }),
            query: { instrumentId },
        })),
        GET_COMPLIANCE: callCreator((orderId) => varReplace(calls.GET_COMPLIANCE.url, { orderId })),
        RUN_COMPLIANCE: callCreator((orderId) => [
            varReplace(calls.RUN_COMPLIANCE.url, { orderId }),
            { method: "POST" },
        ]),
        UPDATE_COMPLIANCE_STATUS: callCreator((orderId, complianceState, reason) => [
            calls.UPDATE_COMPLIANCE_STATUS.url,
            {
                body: {
                    complianceState,
                    reason: reason.trim(),
                    orderID: orderId,
                },
            },
        ]),
        GET_ACCOUNTS: callCreator(() => calls.GET_ACCOUNTS.url),
        GET_ALLOCATIONS_TEMPLATES: callCreator(() => calls.GET_ALLOCATIONS_TEMPLATES.url),
        GET_TABLE_METADATA: callCreator(() => calls.GET_TABLE_METADATA.url),
        GET_COMPLIANCE_TEMPLATES: callCreator(() => calls.GET_COMPLIANCE_TEMPLATES.url),
        GET_COMPLIANCE_RULES: callCreator(() => calls.GET_COMPLIANCE_RULES.url),
        GET_QUOTES: callCreator((orderId) => varReplace(calls.GET_QUOTES.url, { orderId })),
        GET_OMS_PREFERENCES: callCreator(() => calls.GET_OMS_PREFERENCES.url),
        GET_OMS_SETTINGS: callCreator(() => calls.GET_OMS_SETTINGS.url),
        IS_ORDER_TRANSMITTABLE: callCreator((body) => [calls.IS_ORDER_TRANSMITTABLE.url, { body }]),
        GET_ELECTRONIC_ROUTING_TARGETS: callCreator((isSwap = false, financialType, instrumentId) => queryString.stringifyUrl({
            url: calls.GET_ELECTRONIC_ROUTING_TARGETS.url,
            query: {
                financialType,
                instrumentId,
                isSwap,
            },
        })),
        GET_ELECTRONIC_ROUTING_BROKER_ACCOUNTS: callCreator((targetCompId, destination) => queryString.stringifyUrl({
            url: calls.GET_ELECTRONIC_ROUTING_BROKER_ACCOUNTS.url,
            query: {
                targetCompId,
                destination,
            },
        })),
        GET_ELECTRONIC_ROUTING_STRATEGY: callCreator((targetCompId, destination, instrumentId, financialSubType) => queryString.stringifyUrl({
            url: calls.GET_ELECTRONIC_ROUTING_STRATEGY.url,
            query: {
                targetCompId,
                destination,
                instrumentId,
                financialSubType,
            },
        })),
        CALCULATE_VARIANCE_NOTIONAL: callCreator((body) => [calls.CALCULATE_VARIANCE_NOTIONAL.url, { body }]),
        GET_ELECTRONIC_ROUTING_DESTINATIONS: callCreator((body) => [calls.GET_ELECTRONIC_ROUTING_DESTINATIONS.url, { body }]),
        GET_ORDER_COLUMNS: callCreator((mobile = false) => queryString.stringifyUrl({
            url: calls.GET_ORDER_COLUMNS.url,
            query: { mobile },
        })),
        SAVE_ORDER_COLUMNS: callCreator((body, mobile = false) => [
            queryString.stringifyUrl({
                url: calls.SAVE_ORDER_COLUMNS.url,
                query: { mobile },
            }),
            { body },
        ]),
        GET_QUICK_ENTRY_CALCULATION: callCreator((body) => [calls.GET_QUICK_ENTRY_CALCULATION.url, { body }]),
        GET_ALLOCATION_REASON_TEMPLATES: callCreator(() => calls.GET_ALLOCATION_REASON_TEMPLATES.url),
        GET_TRADE_REASON_TEMPLATES: callCreator(() => calls.GET_TRADE_REASON_TEMPLATES.url),
        GET_DARK_POOLS: callCreator(() => calls.GET_DARK_POOLS.url),
        GET_OPTIMIZE_TYPES: callCreator(() => calls.GET_OPTIMIZE_TYPES.url),
        GET_DAY_COUNTS: callCreator(() => calls.GET_DAY_COUNTS.url),
        GET_OPTIMIZED_ALLOCATION_QTY: callCreator((body) => [calls.GET_OPTIMIZED_ALLOCATION_QTY.url, { body }]),
        GET_OPTIMIZED_ALLOCATION: callCreator((orderId) => varReplace(calls.GET_OPTIMIZED_ALLOCATION.url, { orderId })),
        VALIDATE_STRATEGY_PARAMS: callCreator((body) => [calls.VALIDATE_STRATEGY_PARAMS.url, { body }]),
    };
};
